import React, {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {MDBTable, MDBSelect, MDBTableBody, MDBTableHead, MDBBtn} from "mdbreact";
import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import cart from "../Cart/Cart";
import moment from "moment";
import "./statistic.css"

const Statistic = (props) => {
    const [mainData, setMainData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [nen, setNen] = useState([new Date().getFullYear()])
    const [years, setYears] = useState(Array.from({ length: (-5) / -1 + 1}, (_, i) => nen[0] - 1 + (i * -1)))
    const [itemId, setItemId] = useState('');
    const [itemsList, setItemsList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [userId, setUserId] = useState('');
    const [searchTermUser, setSearchTermUser] = useState('');
    const [suggestionsUser, setSuggestionsUser] = useState([]);
    const [sedoy, setSedoy] = useState([]);
    const [preDefinedOrgs, setPreDefinedOrgs] = useState([]);

    const [userList, setUserList] = useState([]);


    const loadData = async () => {
        try {
            const itemsData = [];
            const uniqueItemId = {};

            let fromOrg = "";
            let toOrg = "";
            let userType = ''

            if (props.userOrg && props.userOrg.reseller && props.org && props.org.buyer) {
                fromOrg = props.user.organization;
                toOrg = props.org.id;
                // console.log('seller');
                userType = 'seller';

                setUserList(props.org.users)
            }
            if (props.userOrg && props.userOrg.maker && props.org && props.org.reseller) {
                fromOrg = props.user.organization;
                toOrg = props.org.id;
                // console.log('maker')
                userType = 'maker';
                setUserList(props.org.users)
            }
            if (props.userOrg && props.userOrg.reseller && props.org && props.org.maker) {
                fromOrg = props.org.id;
                toOrg = props.user.organization;
                // console.log('sellerBuyer')
                userType = 'buyer';
                setUserList(props.userOrg.users)
            }
            if (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller) {
                fromOrg = props.org.id;
                toOrg = props.user.organization;
                // console.log('buyer')
                userType = 'buyer';
                setUserList(props.userOrg.users)
            }
            if (props.orgSeller && props.org && props.user && props.user.admin) {
                fromOrg = props.orgSeller.id;
                toOrg = props.org.id;
                // console.log('admin')
                userType = 'admin';
                setUserList(props.org.users)
            }

            // console.log('from to', fromOrg, toOrg, props)

            // console.log(props.org)

            // console.log(fromOrg, toOrg, props)
            // if (fromOrg == "" || toOrg == ""){
            //     return;
            // }

            const querySnapshot = await props.db.collection('orders')
                // .where("isShipped", "==", true)
                // .where("toUser", "==", props.user.id)
                // .where("fromUser", "==", props.user.id)
                .where("fromOrganization", "==", toOrg)
                .where("toOrganization", "==", fromOrg)
                // .where("toUser", "==", "0YjKlhBWGwOweeI1YP54HwLaaWT2")
                .get();

            const data = querySnapshot.docs.map(doc => ({...doc.data(), order_id: doc.id}));

            data.forEach((item) => {
                if (item && item.cart) {
                    item.cart.items.forEach((el) => {
                        if (!uniqueItemId[el.id]) {
                            uniqueItemId[el.id] = true;
                            itemsData.push({
                                id: el.id,
                                name: el.name,
                            });
                        }
                    })
                }
            });

            setItemsList(itemsData);
            setMainData(data);
        } catch (e) {
            console.log('loadData throw',e)
            throw e;
        }
    }

    const loadOrgs = async () => {
        const qSnap = await props.db.collection('organizations').get();
        const dta = qSnap.docs.map(doc => ({...doc.data(), id: doc.id}));
        setPreDefinedOrgs(dta);
    }

    useEffect(() => {
        // console.log(props)
        if (props && props.userOrg || props && props.orgSeller) {
            setItemsList([]);
            setMainData([]);
            setChartData([]);
            setTableData([]);
            setUserId('');
            setUserList([]);
            setSearchTermUser('');
            setSuggestionsUser([]);
            setItemId('');
            setSearchTerm('');
            setSuggestions([]);
            loadData();
        }
    }, [props.userOrg, props.org, props.orgSeller]);

    useEffect(() => {
        const searchData = searchFunction(mainData, nen, itemId, userId);
        const forChartGeneratedData = generateStructure(searchData);
        console.log('forChartGeneratedData', forChartGeneratedData)
        setChartData(forChartGeneratedData);
    }, [mainData, nen, itemId, userId])

    useEffect(() => {
        loadOrgs();
    }, [])

    const searchFunction = (data, years, item_id, userId) => {
        const filteredData = [];

        if (userId) {
            data = data.filter(item => item.fromUser === userId);
        }

        data.forEach(item => {
            const cartItems = item.cart.items;
            const filteredItems = cartItems.filter(cartItem => {
                if (cartItem.okurijyoNo && cartItem.okurijyoNo.length > 0 && cartItem.okurijyoNo.slice(-1)[0].hassouDate) {
                    const hassouDate = cartItem.okurijyoNo.slice(-1)[0].hassouDate;
                    const hassouYear = hassouDate.toDate().getFullYear();
                    const {id} = cartItem
                    if (item_id) {
                        return years.includes(hassouYear) && id === item_id;
                    }
                    return years.includes(hassouYear);
                }
            });

            if (filteredItems.length > 0) {
                filteredData.push({
                    ...item,
                    cart: {
                        ...item.cart,
                        items: filteredItems
                    }
                });
            }
        });

        // console.log('filteredData', filteredData)

        let templatedData = [];

        for (const obj of filteredData) {
            const items = obj.cart.items;
            for (const e of items) {
                if (e.okurijyoNo && e.okurijyoNo.length > 0 && e.okurijyoNo.slice(-1)[0].hassouDate) {
                    const hassouDate = e.okurijyoNo.slice(-1)[0].hassouDate;
                    const okuriJyoValue = e.okurijyoNo.slice(-1)[0].value;

                    templatedData.push({
                        a: hassouDate.toDate().getFullYear(),
                        b: hassouDate.toDate().getMonth() + 1,
                        c: hassouDate.toDate().getDate(),
                        d: `${hassouDate.toDate().getHours().toString().padStart(2, '0')}:${hassouDate.toDate().getMinutes().toString().padStart(2, '0')}:${hassouDate.toDate().getSeconds().toString().padStart(2, '0')}`,
                        e: moment(hassouDate.toDate()).format('YYYY/MM/DD'),
                        f: obj.orderDate ?
                            `${obj.orderDate.toDate().getFullYear()}/${(obj.orderDate.toDate().getMonth() + 1).toString().padStart(2, '0')}/${obj.orderDate.toDate().getDate().toString().padStart(2, '0')}, ${obj.orderDate.toDate().getHours().toString().padStart(2, '0')}:${obj.orderDate.toDate().getMinutes().toString().padStart(2, '0')}:${obj.orderDate.toDate().getSeconds().toString().padStart(2, '0')}`
                            : obj.latestUpdate &&
                            `${obj.latestUpdate.toDate().getFullYear()}/${(obj.latestUpdate.toDate().getMonth() + 1).toString().padStart(2, '0')}/${obj.latestUpdate.toDate().getDate().toString().padStart(2, '0')}, ${obj.latestUpdate.toDate().getHours().toString().padStart(2, '0')}:${obj.latestUpdate.toDate().getMinutes().toString().padStart(2, '0')}:${obj.latestUpdate.toDate().getSeconds().toString().padStart(2, '0')}`,
                        g: obj.order_id,
                        h: items.indexOf(e) + 1,
                        i: obj.cart.orderNo,
                        j: e.id,
                        k: e.name,
                        l: e.price,
                        m: e.count,
                        n: e.price * e.count,
                        o: e.nouki && e.nouki.slice(-1)[0].sdate && moment(e.nouki.slice(-1)[0].sdate.toDate()).format('YYYY-MM-DD'),
                        p: e.nouki && e.nouki.slice(-1)[0].edate && moment(e.nouki.slice(-1)[0].edate.toDate()).format('YYYY-MM-DD'),
                        q: okuriJyoValue,
                        r: e.code,
                        s: e.description,
                        t: obj.cart.orderBikou,
                        u: obj.cart.orderBasyo,
                        v: obj.cart.orderNouki,
                        w: obj.cart.orderNoukiDate && `${obj.cart.orderNoukiDate.toDate().getFullYear()}/${(obj.cart.orderNoukiDate.toDate().getMonth() + 1).toString().padStart(2, '0')}/${obj.cart.orderNoukiDate.toDate().getDate().toString().padStart(2, '0')}, ${obj.cart.orderNoukiDate.toDate().getHours().toString().padStart(2, '0')}:${obj.cart.orderNoukiDate.toDate().getMinutes().toString().padStart(2, '0')}:${obj.cart.orderNoukiDate.toDate().getSeconds().toString().padStart(2, '0')}`,
                        x: obj.cart.orderNoukiDay,
                        y: obj.cart.orderAddr1,
                        z: obj.cart.orderAddr2,
                        aa: obj.cart.orderJigyosyo,
                        ab: obj.cart.orderTanto,
                        ac: obj.cart.orderPhone,
                        ad: e.maker,
                        ae: e.makeredicode,
                        af: e.makerordercode,
                        ag: e.sellercode,
                        ah: obj.fromOrganization,
                        ai: rtrnOrgDta(obj.fromOrganization, 'company_name'),
                        aj: rtrnOrgDta(obj.fromOrganization, 'name'),
                        ak: obj.apply && Object.getOwnPropertyNames(obj.apply)[0],
                        al: obj.applyUserName && obj.applyUserName,
                        am: obj.fromUser,
                        an: obj.fromUserName,
                        ao: obj.toOrganization,
                        ap: rtrnOrgDta(obj.toOrganization, 'company_name'),
                        aq: rtrnOrgDta(obj.toOrganization, 'name'),
                        ar: obj.cart.toUser,
                        as: obj.cart.toUserName,
                        at: obj.cart.deliveryAddress && obj.cart.deliveryAddress.propertyName,
                        au: obj.cart.deliveryAddress && obj.cart.deliveryAddress.postCode,
                        av: obj.cart.deliveryAddress && obj.cart.deliveryAddress.address1,
                        aw: obj.cart.deliveryAddress && obj.cart.deliveryAddress.address2,
                        ax: obj.cart.deliveryAddress && obj.cart.deliveryAddress.officeName,
                        ay: obj.cart.deliveryAddress && obj.cart.deliveryAddress.personInCharge,
                        az: obj.cart.deliveryAddress && obj.cart.deliveryAddress.phoneNumber,
                        ba: obj.isShipped,
                        bb: obj.cartNo,
                    })
                }
            }
        }

        let sortedData = templatedData.sort((d1, d2) => {
            let a1 = new Date(d1.e);
            let a2 = new Date(d2.e);
            return a2.getTime() - a1.getTime();
        })

        // console.log('templatedData', templatedData, sortedData)

        setSedoy(sortedData);

        return filteredData;
    }

    const generateStructure = (data) => {
        const structure = {
            1: {},
            2: {},
            3: {},
            4: {},
            5: {},
            6: {},
            7: {},
            8: {},
            9: {},
            10: {},
            11: {},
            12: {},
        };
        const years = new Set();

        data.forEach((obj) => {
            const items = obj.cart.items;
            // const itemsPriceSum = items.reduce((sum, currentItem) => sum + currentItem.price, 0);
            items.forEach((item) => {
                if (item.okurijyoNo && item.okurijyoNo.length > 0 && item.okurijyoNo.slice(-1)[0].hassouDate) {
                    const hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate;
                    const date = hassouDate.toDate();
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;

                    if (!structure[month]) {
                        structure[month] = {};
                    }

                    if (!structure[month][year+' 数量']) {
                        structure[month][year+' 数量'] = 0;
                        years.add(year+' 数量');
                    }

                    if (!structure[month][year+' 金額']) {
                        structure[month][year+' 金額'] = 0;
                        years.add(year+' 金額');
                    }

                    if (!structure[month][year+' 返品数量']) {
                        structure[month][year+' 返品数量'] = 0;
                        years.add(year+' 返品数量')
                    }

                    if (!structure[month][year+' 返品金額']) {
                        structure[month][year+' 返品金額'] = 0;
                        years.add(year+' 返品金額')
                    }

                    if (!item.divided && !item.returnSub) {
                        structure[month][year+' 数量'] += item.count;
                        structure[month][year+' 金額'] += item.price * item.count;
                    }

                    if (item.returnConfirm) {
                        const returnConfirmDate = item.returnConfirmDate;
                        const rcDate = returnConfirmDate.toDate();
                        const rcYear = rcDate.getFullYear();
                        const rcMonth = rcDate.getMonth() + 1;

                        if (!structure[rcMonth][rcYear+' 返品数量']) {
                            structure[rcMonth][rcYear+' 返品数量'] = 0;
                            years.add(rcYear+' 返品数量')
                        }

                        if (!structure[rcMonth][rcYear+' 返品金額']) {
                            structure[rcMonth][rcYear+' 返品金額'] = 0;
                            years.add(rcYear+' 返品金額')
                        }

                        // console.log(item, rcYear, rcMonth)
                        structure[rcMonth][rcYear+' 返品数量'] += item.count;
                        structure[rcMonth][rcYear+' 返品金額'] += (item.price * item.count)*-1;
                    }
                }
            });
        });

        const sortedYears = Array.from(years);
        sortedYears.sort((a, b) => b.substring(0, 4) - a.substring(0, 4));

        // console.log(structure, sortedYears)


        //TODO: table dr xaruulax data ugsarj baigaa
        let tableDataTemp = {};
        for (let i=0; i<sortedYears.length; i++) {
            let yearArr = sortedYears[i].split(' ');
            if (tableDataTemp[yearArr[0]]) {
                tableDataTemp[yearArr[0]][yearArr[1]] = {};
            } else {
                tableDataTemp[yearArr[0]] = {};
                tableDataTemp[yearArr[0]][yearArr[1]] = {};
            }
            for (let a=1; a<=12; a++) {
                if (structure[a]) {
                    tableDataTemp[yearArr[0]][yearArr[1]][a] = structure[a][sortedYears[i]] || 0;
                } else {
                    tableDataTemp[yearArr[0]][yearArr[1]][a] = 0;
                }
            }
        }

        setTableData(tableDataTemp);

        const result = [];

        const header = ["month", ...Array.from(sortedYears).map(String)];
        result.push(header);

        for (const month in structure) {
            const row = [month * 1];
            const counts = structure[month];

            for (const year of sortedYears) {
                const count = counts[year] || null;
                row.push(count);
            }

            result.push(row);
        }

        return result;
    }

    const handleNenChange = (e) => {
        const {value} = e.currentTarget;
        const arr = [nen[0], value * 1];
        setNen(arr);
    }

    const handleItemChange = (e) => {
        const {value} = e.currentTarget;
        if (!value) {
            setSearchTerm('');
        }
        setItemId(value);
    }

    const handleSelectChangeUser = (e) => {
        const {value} = e.currentTarget;
        console.log(value)
        if (!value) {
            setSearchTermUser('');
        }
        setUserId(value);
    }

    const handleInputChange = (e) => {
        const {value} = e.currentTarget;
        setSearchTerm(value);
        setSuggestions(itemsList.filter(suggestion =>
            suggestion.name.includes(value)
        ));
    }

    const handleInputChangeUser = (e) => {
        const {value} = e.currentTarget;
        setSearchTermUser(value);
        setSuggestionsUser(userList.filter(suggestion =>
            suggestion.name.includes(value)
        ));
    }

    // using promise
    // const returnOrgData = async (orgDocId, fieldName) => {
    //     const qSnapShot = await props.db.collection('organizations').doc(orgDocId).get();
    //     const data = qSnapShot.data();
    //     if (data) {
    //         return data[`${fieldName}`];
    //     }
    //     return '';
    // }

    const rtrnOrgDta = (orgDocId, fieldName) => {
        let rs = preDefinedOrgs.find(x => x.id === orgDocId);
        if (rs) {
            return rs[`${fieldName}`];
        }
        return '';
    }

    const exportDataToCSV = async (data) => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        worksheet.getCell('A1').value = '出荷（年）';
        worksheet.getCell('B1').value = '出荷（月）';
        worksheet.getCell('C1').value = '出荷（日）';
        worksheet.getCell('D1').value = '出荷（時間）';
        worksheet.getCell('E1').value = '出荷年月日';
        worksheet.getCell('F1').value = '発注日';
        worksheet.getCell('G1').value = 'P4CLOUD発注書ID';
        worksheet.getCell('H1').value = '発注行';
        worksheet.getCell('I1').value = '発注番号';
        worksheet.getCell('J1').value = '商品ID';
        worksheet.getCell('K1').value = '商品名';
        worksheet.getCell('L1').value = '単価';
        worksheet.getCell('M1').value = '数量';
        worksheet.getCell('N1').value = '商品計';
        worksheet.getCell('O1').value = '納期(s)';
        worksheet.getCell('P1').value = '納期(e)';
        worksheet.getCell('Q1').value = '送り状番号';
        worksheet.getCell('R1').value = '商品コード';
        worksheet.getCell('S1').value = '商品行備考';
        worksheet.getCell('T1').value = '発注書備考';
        worksheet.getCell('U1').value = '納品先選択';
        worksheet.getCell('V1').value = '納期選択';
        worksheet.getCell('W1').value = '納期日付指定';
        worksheet.getCell('X1').value = '納期日数指定';
        worksheet.getCell('Y1').value = 'D納品先住所１';
        worksheet.getCell('Z1').value = 'D納品先住所２';
        worksheet.getCell('AA1').value = 'D納品先事業所';
        worksheet.getCell('AB1').value = 'D納品先担当者';
        worksheet.getCell('AC1').value = 'D納品先電話番号';
        worksheet.getCell('AD1').value = 'メーカー名';
        worksheet.getCell('AE1').value = 'メーカーコード';
        worksheet.getCell('AF1').value = 'メーカー発注コード';
        worksheet.getCell('AG1').value = '仕入先コード';
        worksheet.getCell('AH1').value = '発注元事業所ID';
        worksheet.getCell('AI1').value = '発注元企業名';
        worksheet.getCell('AJ1').value = '発注元事業所名';
        worksheet.getCell('AK1').value = '発注承認者ID';
        worksheet.getCell('AL1').value = '発注承認者名';
        worksheet.getCell('AM1').value = '発注者ID';
        worksheet.getCell('AN1').value = '発注者名';
        worksheet.getCell('AO1').value = '発注先事業所ID';
        worksheet.getCell('AP1').value = '発注先企業名';
        worksheet.getCell('AQ1').value = '発注先事業所名';
        worksheet.getCell('AR1').value = '発注先担当者ID';
        worksheet.getCell('AS1').value = '発注先担当者名';
        worksheet.getCell('AT1').value = 'S納品先名';
        worksheet.getCell('AU1').value = 'S納品先郵便番号';
        worksheet.getCell('AV1').value = 'S納品先住所１';
        worksheet.getCell('AW1').value = 'S納品先住所２';
        worksheet.getCell('AX1').value = 'S納品先事業所名';
        worksheet.getCell('AY1').value = 'S納品先担当者名';
        worksheet.getCell('AZ1').value = 'S納品先電話番号';
        worksheet.getCell('BA1').value = '発注書内商品全出荷';
        worksheet.getCell('BB1').value = 'カート番号';

        let row = 2;

        for await (const obj of data) {
            // const items = obj.cart.items;
            // for await (const e of items) {
            //     if (e.okurijyoNo && e.okurijyoNo.length > 0 && e.okurijyoNo.slice(-1)[0].hassouDate) {
            //         const hassouDate = e.okurijyoNo.slice(-1)[0].hassouDate;
            //         const okuriJyoValue = e.okurijyoNo.slice(-1)[0].value;

                    worksheet.getCell(`A${row}`).value = obj.a;
                    worksheet.getCell(`B${row}`).value = obj.b;
                    worksheet.getCell(`C${row}`).value = obj.c;
                    worksheet.getCell(`D${row}`).value = obj.d;
                    worksheet.getCell(`E${row}`).value = obj.e;
                    worksheet.getCell(`F${row}`).value = obj.f;
                    worksheet.getCell(`G${row}`).value = obj.g;
                    worksheet.getCell(`H${row}`).value = obj.h;
                    worksheet.getCell(`I${row}`).value = obj.i;
                    worksheet.getCell(`J${row}`).value = obj.j;
                    worksheet.getCell(`K${row}`).value = obj.k;
                    worksheet.getCell(`L${row}`).value = obj.l;
                    worksheet.getCell(`M${row}`).value = obj.m;
                    worksheet.getCell(`N${row}`).value = obj.n;
                    worksheet.getCell(`O${row}`).value = obj.o;
                    worksheet.getCell(`P${row}`).value = obj.p;
                    worksheet.getCell(`Q${row}`).value = obj.q;
                    worksheet.getCell(`R${row}`).value = obj.r;
                    worksheet.getCell(`S${row}`).value = obj.s;
                    worksheet.getCell(`T${row}`).value = obj.t;
                    worksheet.getCell(`U${row}`).value = obj.u;
                    worksheet.getCell(`V${row}`).value = obj.v;
                    worksheet.getCell(`W${row}`).value = obj.w;
                    worksheet.getCell(`X${row}`).value = obj.x;
                    worksheet.getCell(`Y${row}`).value = obj.y;
                    worksheet.getCell(`Z${row}`).value = obj.z;
                    worksheet.getCell(`AA${row}`).value = obj.aa;
                    worksheet.getCell(`AB${row}`).value = obj.ab;
                    worksheet.getCell(`AC${row}`).value = obj.ac;
                    worksheet.getCell(`AD${row}`).value = obj.ad;
                    worksheet.getCell(`AE${row}`).value = obj.ae;
                    worksheet.getCell(`AF${row}`).value = obj.af;
                    worksheet.getCell(`AG${row}`).value = obj.ag;
                    worksheet.getCell(`AH${row}`).value = obj.ah;
                    worksheet.getCell(`AI${row}`).value = obj.ai;
                    worksheet.getCell(`AJ${row}`).value = obj.aj;
                    worksheet.getCell(`AK${row}`).value = obj.ak;
                    worksheet.getCell(`AL${row}`).value = obj.al;
                    worksheet.getCell(`AM${row}`).value = obj.am;
                    worksheet.getCell(`AN${row}`).value = obj.an;
                    worksheet.getCell(`AO${row}`).value = obj.ao;
                    worksheet.getCell(`AP${row}`).value = obj.ap;
                    worksheet.getCell(`AQ${row}`).value = obj.aq;
                    worksheet.getCell(`AR${row}`).value = obj.ar;
                    worksheet.getCell(`AS${row}`).value = obj.as;
                    worksheet.getCell(`AT${row}`).value = obj.at;
                    worksheet.getCell(`AU${row}`).value = obj.au;
                    worksheet.getCell(`AV${row}`).value = obj.av;
                    worksheet.getCell(`AW${row}`).value = obj.aw;
                    worksheet.getCell(`AX${row}`).value = obj.ax;
                    worksheet.getCell(`AY${row}`).value = obj.ay;
                    worksheet.getCell(`AZ${row}`).value = obj.az;
                    worksheet.getCell(`BA${row}`).value = obj.ba;
                    worksheet.getCell(`BB${row}`).value = obj.bb;


                    row++;
                // }
            // }
        }

        //
        // for (const year in data) {
        //     for (const month in data[year]['数量']) {
        //         const quantity = data[year]['数量'][month];
        //         const amount = data[year]['金額'][month];
        //
        //         worksheet.getCell(`A${row}`).value = year;
        //         worksheet.getCell(`B${row}`).value = month;
        //         worksheet.getCell(`C${row}`).value = quantity;
        //         worksheet.getCell(`D${row}`).value = amount;
        //
        //         row++;
        //     }
        // }
        //
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer]);
            saveAs(blob, `${new Date().getFullYear()}${(new Date().getMonth() + 1).toString().padStart(2, '0')}${new Date().getDate().toString().padStart(2, '0')}-${data[0].ao}-shipped-${new Date().getHours().toString().padStart(2, '0')}${new Date().getMinutes().toString().padStart(2, '0')}${new Date().getSeconds().toString().padStart(2, '0')}.xlsx`);
        });
    }

    const formatTooltipValue = (value) => {
        // Apply the regex pattern to format the value
        const formattedValue = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

        return formattedValue;
    }

    const handleSuggestionClick = (suggestion) => {
        setItemId(suggestion.id);
        setSearchTerm(suggestion.name);
        setSuggestions([]);
    }

    const handleSuggestionClickUser = (suggestion) => {
        setUserId(suggestion.user);
        setSearchTermUser(suggestion.name);
        setSuggestionsUser([]);
    }

    const handleClearClick = () => {
        setItemId('');
        setSearchTerm('');
        setSuggestions([]);
    };

    const handleClearClickUser = () => {
        setUserId('');
        setSearchTermUser('');
        setSuggestionsUser([]);
    };


    const options = [
        {
            text: 'Option 1',
            value: 'option1',
        },
        {
            text: 'Option 2',
            value: 'option2',
        },
        {
            text: 'Option 3',
            value: 'option3',
        },
        // Add more options as needed
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: .2 }}>
                <div>
                    <p>比較年度選択</p>
                    <select className="browser-default custom-select" onChange={handleNenChange}>
                        <option value="">未選択</option>
                        {years.map((item, index) => (
                            <option key={index} value={item.toString()}>{item + '年度'}</option>
                        ))}
                    </select>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <p>商品選択</p>
                    <select value={itemId} defaultValue={itemId} className="browser-default custom-select" onChange={handleItemChange}>
                        <option value="">全商品</option>
                        {itemsList.map((item, key) => (
                            <option key={key} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div style={styles.searchField}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="商品名検索..."
                            style={styles.searchInput}
                        />
                        {searchTerm && (
                            <button style={styles.clearButton} onClick={handleClearClick}>
                                X
                            </button>
                        )}
                    </div>
                    {suggestions.length > 0 && (
                        <ul style={styles.suggestionList}>
                            {suggestions.map((suggestion, index) => (
                                <li style={styles.suggestionItems} key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                    {suggestion.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div style={{ marginTop: '20px' }}>
                    <p>担当者選択</p>
                    <select value={userId} defaultValue={userId} className="browser-default custom-select" onChange={handleSelectChangeUser}>
                        <option value="">全員</option>
                        {userList.map((item, key) => (
                            <option key={key} value={item.user}>{item.name}</option>
                        ))}
                    </select>
                </div>
                <div style={styles.searchField}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <input
                            type="text"
                            value={searchTermUser}
                            onChange={handleInputChangeUser}
                            disabled={(
                                // (props.userOrg && props.userOrg.buyer && props.org && props.org.reseller)
                                // ||
                                (props.userOrg && props.userOrg.reseller && props.org && props.org.maker))}
                            placeholder="担当者検索..."
                            style={styles.searchInput}
                        />
                        {searchTermUser && (
                            <button style={styles.clearButton} onClick={handleClearClickUser}>
                                X
                            </button>
                        )}
                    </div>
                    {suggestionsUser.length > 0 && (
                        <ul style={styles.suggestionList}>
                            {suggestionsUser.map((suggestion, index) => (
                                <li style={styles.suggestionItems} key={index} onClick={() => handleSuggestionClickUser(suggestion)}>
                                    {suggestion.name}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            <div style={{ flex: .8 }}>
                <div style={{ marginLeft: '30px' }}>
                    <p>{itemId ? itemsList.find(item => item.id === itemId).name : '全商品'}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: 20 }}>
                        <p>比較年度: {nen && nen[1] > 0 ? `${nen[1]}年 - ${nen[0]}年` : '未選択'}</p>
                        <p>担当者: {userId ? userList.find(item => item.user === userId).name : '未選択'}</p>
                    </div>
                </div>
                <div style={{ height: 400 }}>
                    {chartData.length > 1 && chartData[0].length > 1 ?
                        <Chart
                            chartType="ComboChart"
                            width="100%"
                            height="400px"
                            data={chartData.map(row => row.map(value => value === null ? 0 : value))}
                            options={{
                                seriesType: "bars",
                                series: {
                                    0: {
                                        targetAxisIndex: 0, type: "line", color: '#7dacd0', pointSize: 10, pointShape: 'circle',
                                    },
                                    1: {
                                        targetAxisIndex: 1, color: '#004e89',
                                    },
                                    2: {
                                        targetAxisIndex: 0, type: "line", color: '#99e9ff', pointSize: 10, pointShape: 'circle',
                                    },
                                    3: {
                                        targetAxisIndex: 1, color: '#00C2FF'
                                    },
                                    4: {
                                        targetAxisIndex: 0, type: "line", color: '#dc967d', pointSize: 10, pointShape: 'circle',
                                    },
                                    5: {
                                        targetAxisIndex: 1, color: '#ff6b35'
                                    },
                                    6: {
                                        targetAxisIndex: 0, type: "line", color: '#99e9ff', pointSize: 10, pointShape: 'circle',
                                    },
                                    7: {
                                        targetAxisIndex: 1, color: '#00C2FF'
                                    },
                                },
                                vAxes: {
                                    0: { title: '数量',format: '0' },
                                    1: { title: '金額',format: '#,##0円' },
                                },
                                hAxis: {
                                    ticks: [
                                        { v: 1, f: '1月' },
                                        { v: 2, f: '2月' },
                                        { v: 3, f: '3月' },
                                        { v: 4, f: '4月' },
                                        { v: 5, f: '5月' },
                                        { v: 6, f: '6月' },
                                        { v: 7, f: '7月' },
                                        { v: 8, f: '8月' },
                                        { v: 9, f: '9月' },
                                        { v: 10, f: '10月' },
                                        { v: 11, f: '11月' },
                                        { v: 12, f: '12月' },
                                    ],
                                },
                                gridlines: {
                                    count: 12,
                                },
                            }}
                        />
                        :
                        <p style={{ color: 'red', marginLeft: '30px' }}>選択年度または選択商品のデータが存在しないためグラフおよび表が表示できません</p>
                    }
                </div>
                {Object.keys(tableData).length > 0 && (
                <>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        {sedoy && sedoy.length > 0 && <MDBBtn onClick={() => exportDataToCSV(sedoy)}>購入データ ダウンロード</MDBBtn>}
                    </div>
                    <MDBTable responsive btn bordered>
                        <MDBTableHead>
                            <tr style={styles.tableHeader}>
                                <th colSpan={3} style={styles.tableHeader}></th>
                                <th>1月</th>
                                <th>2月</th>
                                <th>3月</th>
                                <th>4月</th>
                                <th>5月</th>
                                <th>6月</th>
                                <th>7月</th>
                                <th>8月</th>
                                <th>9月</th>
                                <th>10月</th>
                                <th>11月</th>
                                <th>12月</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {Object.keys(tableData).map((value, index) => {
                                let m = tableData[value]['金額'];
                                let n = tableData[value]['数量'];
                                let o = tableData[value]['返品金額'];
                                let p = tableData[value]['返品数量'];
                                return <>
                                    <tr>
                                        <td rowSpan={4} style={{ textAlign: 'center', width: '120px' }}>{value}<br/>年度</td>
                                        <td style={styles.tableHead} className={"vertical"}>金額</td>
                                        <td style={styles.tableHead}>円</td>
                                        <td style={styles.tableContent}>{m[1].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[2].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[3].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[4].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[5].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[6].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[7].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[8].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[9].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[10].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[11].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{m[12].toLocaleString()}</td>
                                    </tr>
                                    <tr style={{backgroundColor: '#0000000d'}}>
                                        <td style={styles.tableHead} className={"vertical"}>数量</td>
                                        <td style={styles.tableHead}>個</td>
                                        <td style={styles.tableContent}>{n[1].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[2].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[3].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[4].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[5].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[6].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[7].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[8].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[9].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[10].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[11].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{n[12].toLocaleString()}</td>
                                    </tr>
                                    <tr key={index}>
                                        <td style={styles.tableHead} className={"vertical"}>返品金額</td>
                                        <td style={styles.tableHead}>円</td>
                                        <td style={styles.tableContent}>{o[1].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[2].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[3].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[4].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[5].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[6].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[7].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[8].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[9].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[10].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[11].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{o[12].toLocaleString()}</td>
                                    </tr>
                                    <tr style={{backgroundColor: '#0000000d'}}>
                                        <td style={styles.tableHead} className={"vertical"}>返品数量</td>
                                        <td style={styles.tableHead}>個</td>
                                        <td style={styles.tableContent}>{p[1].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[2].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[3].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[4].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[5].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[6].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[7].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[8].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[9].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[10].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[11].toLocaleString()}</td>
                                        <td style={styles.tableContent}>{p[12].toLocaleString()}</td>
                                    </tr>
                                </>
                            })}
                        </MDBTableBody>
                    </MDBTable>
                </>
                )}
            </div>
        </div>
    )
}

const styles = {
    tableHeader:{
        backgroundColor: '#0000000d',
        textAlign: 'center',
        width: '80px'
    },
    tableHead:{
        backgroundColor: '#fff',
        textAlign: 'center',
        width: '80px'
    },
    tableContent:{
        textAlign: 'right',
        width: '80px'
    },
    searchField: {
        marginTop: '20px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    },
    searchInput: {
        padding: '8px',
        fontSize: '16px',
        border: '1px solid #CCC',
        borderRadius: '4px',
        width: '100%',
    },
    suggestionList: {
        listStyleType: 'none',
        backgroundColor: '#FFF',
        border: '1px solid #CCC',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        padding: 0,
        margin: 0,
        maxHeight: '200px',
        overflowY: 'auto'

    },
    suggestionItems: {
        padding: '8px',
        cursor: 'pointer',
    },
    clearButton: {
        background: 'none',
        border: 'none',
        color: '#888',
        cursor: 'pointer',
        fontSize: '14px',
        marginLeft: '4px'
    }
}

export default Statistic;
