import React from "react";
import { withRouter } from 'react-router-dom'
import {Layout, Button, Dropdown, Space, AutoComplete, Flex, Input, Badge, Select,Tooltip} from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DownOutlined,
    MailOutlined,
    UserOutlined, InfoOutlined, InfoCircleOutlined
} from '@ant-design/icons';
const { Header } = Layout;

const Title = (props) => (
    <Flex align="center" justify="space-between">
        {props.title}
        <a href="https://www.google.com/search?q=antd" target="_blank" rel="noopener noreferrer">
            more
        </a>
    </Flex>
);

class P4Header1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            organization: null,
            company: null,
            seller: null,
            email: null,
            seller_company: null,
            organizationUnread: {},
            unread: 0,
            unreadNotShipped: 0,
            sellerOrganizations: [],
            buyerOrganizations: [],
            sellerOrg: null,
        }
    }

    sumObjectsByKey = (...objs) => {
        return objs.reduce((a, b) => {
            for (let k in b) {
                if (b.hasOwnProperty(k))
                    a[k] = (a[k] || 0) + b[k];
            }
            return a;
        }, {});
    }

    componentDidMount() {
        this.organizationCheck();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // console.log('componentWillReceiveProps', nextProps.cancelOrder, nextProps.cancelOrderWithOrg)
        if ((nextProps.orgSeller && nextProps.orgSeller.id) !== this.state.sellerOrg) {
            // console.log(nextProps.orgSeller.id)
            this.setState({
                sellerOrg: nextProps.orgSeller.id
            }, () => {
                this.selectSellerOrganization(nextProps.orgSeller, true);
            })
        }

        this.setState({
            organizationUnread: this.sumObjectsByKey(
                nextProps.unreadOrderWithOrg,
                nextProps.unreadBillWithOrg,
                nextProps.unreadDeliveryNotesWithOrg,
                nextProps.unreadEstimates2WithOrg,
                nextProps.unreadEstimatesWithOrg,
                nextProps.unreadReportWithOrg,
                nextProps.unreadOCHWithOrg,
                // nextProps.sdsWaitingCountWithOrg
            ),
            unread: (
                nextProps.unreadOrder+
                nextProps.unreadBill+
                nextProps.unreadDeliveryNotes+
                nextProps.unreadEstimates2+
                nextProps.unreadEstimates+
                nextProps.unreadReport+
                nextProps.unreadOCH
                // nextProps.sdsWaitingCount
            ),
            unreadNotShipped: nextProps.notShippedOrder
        }, () => {
            // console.log(this.state.organizationUnread)
        })
    }

    selectSellerOrganization = (seller, initial) => {
    //this.setState({activeOrg1: {organization: seller.id, name: seller.company_name + ' ' + seller.name}});
// this.props.orgSelect(e)
        this.setState({
            buyerOrganizations: seller.buyers || []
        })
        this.props.orgSelectSeller(seller.id, (seller.company_name || '') + ' ' + seller.name, initial);
        // console.log('this.props.org && this.props.org.display_name',this.props.org && this.props.org.display_name,this.state.searchOrg);
        // console.log('this.props',this.props);
    }

    organizationCheck = () => {
        if(this.props.user && this.props.user.organization){
            let organizationRef = this.props.db.collection('organizations').doc(this.props.user.organization);
            let getDoc = organizationRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such organization document!')
                    } else {
                        if ( doc.data().active ) {
                            let org = doc.data();
                            org.id = doc.id;

                            this.setState({
                                organization: org,
                            });

                            this.companyCheck();
                            this.sellerCheck();
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting organization document', this.props.user.organization, err)
                })
        } else if (this.props.user && this.props.user.admin) {
            this.adminUserList()
                .then((snapshot) => {
                    if (snapshot.length > 0) {
                        let organizations = [];
                        snapshot.forEach((doc) => {
                            let data = doc.data();
                            data.id = doc.id;
                            organizations.push(data)
                        });

                        this.setState({
                            sellerOrganizations: organizations,
                            buyerOrganizations: []
                        })
                    } else {
                        console.log('No such organization document!')
                    }
                })
        }

    }

    adminUserList = async () => {
        const seller = this.props.db.collection('organizations').where('reseller', '==', true).get();
        const maker = this.props.db.collection('organizations').where('maker', '==', true).get();

        const [
            sellerSnapshot,
            makerSnapshot
        ] = await Promise.all([seller, maker]);

        const sellerArray = sellerSnapshot.docs || [];
        const makerArray = makerSnapshot.docs || [];

        //Note that we don't need to de-duplicate in this case
        return sellerArray.concat(makerArray);
    }

    companyCheck = () => {
        if(this.state.organization){
            let companyRef = this.props.db.collection('company').doc(this.state.organization.company);
            let getDoc = companyRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such company document!')
                    } else {
                        if ( doc.data().active ) {
                            let company = doc.data();
                            company.id = doc.id;
                            this.setState({
                                company: company,
                            });
                            let org = this.state.organization;
                            org.display_name = company.name + ' ' + org.name;
                            console.log('org', org.display_name)

                            this.props.setupOrgAndCompany(org);
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting company document', err)
                })
        }
    }

    sellerCheck = () => {
        if(this.state.organization && this.state.organization.seller){
            let sellerRef = this.props.db.collection('organizations').doc(this.state.organization.seller);
            let getDoc = sellerRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such seller document!')
                    } else {
                        if ( doc.data().active ) {
                            let seller = doc.data();
                            seller.id = doc.id;
                            this.setState({
                                seller: seller,
                            });
                            // console.log('seller', seller)
                            this.seller_companyCheck();
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting seller document', err)
                })
        }
    }

    seller_companyCheck = () => {
        if(this.state.organization){
            let companyRef = this.props.db.collection('company').doc(this.state.seller.company);
            let getDoc = companyRef.get()
                .then(doc => {
                    if (!doc.exists) {
                        console.log('No such seller_company document!')
                    } else {
                        if ( doc.data().active ) {
                            this.setState({
                                seller_company: doc.data(),
                            });
                            this.props.orgSelectData(this.state.seller.id, this.state.seller_company.name + ' ' + this.state.seller.name);
                        }
                    }
                })
                .catch(err => {
                    console.log('Error getting seller_company document', err)
                })
        }
    }

    handleMenuClick = (e) => {
        switch (e.key) {
            case '1':
                this.props.history.push('/emailsettings')
                break;
            case '11':
                this.props.history.push('/support')
                break;
            case '2':
                this.props.logout();
                break;
        }
    }

    selectOrg = (value, option) => {
        this.setState({
            activeOrg: {
                organization: option.id,
                name: option.name
            }
        });
        this.props.orgSelect(option.id, option.name);
    }

    render() {
        const renderItem = (title, count) => ({
            value: title,
            label: (
                <Flex align="center" justify="space-between">
                    {title}
                    <span>
                        <UserOutlined /> {count}
                    </span>
                </Flex>
            ),
        });

        // console.log('this.state.organization', this.state.organization, this.state.sellerOrganizations, this.state.buyerOrganizations)

        let orgList = [];
        let orgList2 = [];
        let sellerOrganizations = this.state.sellerOrganizations;
        if (this.state.searchOrg) {
            sellerOrganizations = this.state.filteredOrg;
        }

        let buyerOrganizations = this.state.buyerOrganizations;
        if (this.state.searchOrg1) {
            buyerOrganizations = this.state.filteredOrg1;
        }

        // buyerOrganizations.map((buyer, i) => {
        //     // console.log('this.props.sdsNormalCountWithOrg[buyer.organization]', buyer);
        //     orgList.push({
        //         id: buyer.organization,
        //         value: `[仕入側] ${buyer.name}`,
        //         label: <span><Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyer.organization] || 0} showZero={false}/>[仕入側] {buyer.name} <Badge color={'green'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyer.organization] || 0} showZero={false}/></span>,
        //         name: buyer.name,
        //         sdsNormal: this.props.sdsNormalCountWithOrg[buyer.organization],
        //         sdsWarning: this.props.sdsWarningCountWithOrg[buyer.organization],
        //         sdsAlert: this.props.sdsAlertCountWithOrg[buyer.organization],
        //         sdsWait: this.props.sdsWaitingCountWithOrg[buyer.organization]
        //     })
        // });

        let buyersOrganizations = this.state.organization && this.state.organization.buyers;
        if (this.state.searchOrgBuyer || this.state.searchOrgBuyerSeller) {
            buyersOrganizations = this.state.filteredOrgBuyer;
        }

        // buyersOrganizations && buyersOrganizations.map((buyer, i) => {
        //     // console.log('this.props.sdsNormalCountWithOrg[buyer.organization]', buyer);
        //     orgList.push({
        //         id: buyer.organization,
        //         value: `[仕入側] ${buyer.name}`,
        //         label: <span style={{display: 'flex', gap: 5}}>
        //             <Badge color={'green'} overflowCount={999} count={this.state.organizationUnread[buyer.organization] || 0} showZero={false}/>
        //             [仕入側] {buyer.name}
        //             <Badge color={'red'} overflowCount={999} count={this.props.notShippedOrderWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'blue'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'yellow'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'red'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //             <Badge color={'green'} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyer.organization] || 0} showZero={false}/>
        //         </span>,
        //         name: buyer.name,
        //         sdsNormal: this.props.sdsNormalCountWithOrg[buyer.organization],
        //         sdsWarning: this.props.sdsWarningCountWithOrg[buyer.organization],
        //         sdsAlert: this.props.sdsAlertCountWithOrg[buyer.organization],
        //         sdsWait: this.props.sdsWaitingCountWithOrg[buyer.organization]
        //     })
        // });

        let sellersOrganizations = this.state.organization && this.state.organization.sellers;
        if (this.state.searchOrgSeller || this.state.searchOrgBuyerSeller) {
            sellersOrganizations = this.state.filteredOrgSeller;
        }

        let customersOrganizations = this.state.organization && this.state.organization.customers;
        if (this.state.searchOrgCustomer) {
            customersOrganizations = this.state.filteredOrgCustomer;
        }

        // console.log('this.props.org.display_name', this.props, this.state.organization);
        // console.log('buyersOrganizations', buyersOrganizations);
        // console.log('sellersOrganizations', sellersOrganizations);
        let addonBeforeName = ''
        if (this.state.organization && this.state.organization.administrator) {
            customersOrganizations.map((customers, i) => {
                orgList.push({
                    id: customers.organization,
                    value: `${customers.name}`,
                    label: <span style={{display: 'flex', gap: 5}}>
                        <Tooltip title={'未読'}><Badge color={'#52c41a'} title={''} count={this.state.organizationUnread[customers.organization] || 0} showZero={false}/></Tooltip>
                        {customers.name}
                        <Tooltip title={'未出荷'}><Badge color={'#f5222d'} title={''} count={this.props.notShippedOrderWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>
                        <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} title={''} overflowCount={999} count={this.props.sdsNormalCountWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>
                        <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} title={''} overflowCount={999} count={this.props.sdsWarningCountWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>
                        <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} title={''} overflowCount={999} count={this.props.sdsAlertCountWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>
                        <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>
                        <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>}
                        <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[customers.organization] || 0} showZero={false}/></Tooltip>}
                    </span>,
                    name: customers.name,
                    organizationUnread: this.state.organizationUnread[customers.organization] || 0,
                    notShippedOrder: this.props.sdsNormalCountWithOrg[customers.organization] || 0,
                    sdsNormal: this.props.sdsNormalCountWithOrg[customers.organization] || 0,
                    sdsWarning: this.props.sdsWarningCountWithOrg[customers.organization] || 0,
                    sdsAlert: this.props.sdsAlertCountWithOrg[customers.organization] || 0,
                    sdsWait: this.props.sdsWaitingCountWithOrg[customers.organization] || 0
                });
            });
        }

        if (this.state.organization && this.state.organization.reseller) {
            if ((this.props.org && this.props.org.display_name && !this.props.location.pathname.startsWith('/measurement'))) {
                addonBeforeName = this.props.org.maker ? '[仕入モード]　' : (this.props.org.buyer ? '[販売モード]' : '');
            } else if(this.props.location.pathname.startsWith('/measurement')) {
                addonBeforeName = this.props.location.pathname.startsWith('/measurement') ? '[測定データモード]' : '取引先を選択してください'
            }
            // orgList.push({
            //     label: <input autoFocus type={'text'} className={'form-control'} placeholder={'名前検索'}
            //                   value={this.state.searchOrgBuyerSeller} onChange={this.handleChangeBuyerSeller}
            //                   onKeyDown={(event) => this.handleKeyDown(event, buyersOrganizations || [], sellersOrganizations || [])}/>
            // })
            if (buyersOrganizations) {
                buyersOrganizations.map((buyers, i) => {
                    orgList.push({
                        id: buyers.organization,
                        value: `[売先] ${buyers.name}`,
                        label:
                            <span style={{display: 'flex', gap: 5, fontSize: '14px'}}>
                         {this.state.organizationUnread[buyers.organization] > 0 &&
                             <Tooltip title={'未読'}><Badge color={'#52c41a'} overflowCount={999} title={''}
                                    count={this.state.organizationUnread[buyers.organization] || 0} showZero={false}/></Tooltip>}
                                [売先] {buyers.name}
                                {this.props.notShippedOrderWithOrg[buyers.organization] > 0 &&
                                    <Tooltip title={'未出荷'}><Badge color={'#f5222d'} overflowCount={999} title={''}
                                           count={this.props.notShippedOrderWithOrg[buyers.organization] || 0}
                                           showZero={false}/></Tooltip>}
                                {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 &&
                                    <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} overflowCount={999} title={''}
                                           count={this.props.sdsNormalCountWithOrg[buyers.organization] || 0}
                                           showZero={false}/></Tooltip>}
                                {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 &&
                                    <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} overflowCount={999} title={''}
                                           count={this.props.sdsWarningCountWithOrg[buyers.organization] || 0}
                                           showZero={false}/></Tooltip>}
                                {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 &&
                                    <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} overflowCount={999} title={''}
                                           count={this.props.sdsAlertCountWithOrg[buyers.organization] || 0}
                                           showZero={false}/></Tooltip>}
                                {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 &&
                                    <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} overflowCount={999} title={''}
                                           count={this.props.sdsWaitingCountWithOrg[buyers.organization] || 0}
                                           showZero={false}/></Tooltip>}
                                {this.props.cancelOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                                {this.props.returnOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                    </span>,
                        name: buyers.name,
                        organizationUnread: this.state.organizationUnread[buyers.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[buyers.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[buyers.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[buyers.organization] || 0
                    });
                });
            }

            if (sellersOrganizations) {
                sellersOrganizations.map((seller, i) => {
                    orgList.push({
                        id: seller.organization,
                        value: `[仕入先] ${seller.name}`,
                        label: <span style={{
                            display: 'flex', gap: 5//,backgroundColor: this.props.org && this.props.org.display_name === seller.name ? '#007bff' : ''
                        }}>
                       {this.state.organizationUnread[seller.organization] > 0 &&
                           <Tooltip title={'未読'}><Badge color={'#52c41a'} overflowCount={999} title={''}
                                  count={this.state.organizationUnread[seller.organization] || 0} showZero={false}/></Tooltip>}
                            [仕入先] {seller.name}
                            {this.props.notShippedOrderWithOrg[seller.organization] > 0 &&
                                <Tooltip title={'未出荷'}><Badge color={'#f5222d'} overflowCount={999} title={''}
                                       count={this.props.notShippedOrderWithOrg[seller.organization] || 0}
                                       showZero={false}/></Tooltip>}
                            {this.props.sdsNormalCountWithOrg[seller.organization] > 0 &&
                                <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} overflowCount={999} title={''}
                                       count={this.props.sdsNormalCountWithOrg[seller.organization] || 0}
                                       showZero={false}/></Tooltip>}
                            {this.props.sdsWarningCountWithOrg[seller.organization] > 0 &&
                                <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} overflowCount={999} title={''}
                                       count={this.props.sdsWarningCountWithOrg[seller.organization] || 0}
                                       showZero={false}/></Tooltip>}
                            {this.props.sdsAlertCountWithOrg[seller.organization] > 0 &&
                                <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} overflowCount={999} title={''}
                                       count={this.props.sdsAlertCountWithOrg[seller.organization] || 0}
                                       showZero={false}/></Tooltip>}
                            {this.props.sdsWaitingCountWithOrg[seller.organization] > 0 &&
                                <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} overflowCount={999} title={''}
                                       count={this.props.sdsWaitingCountWithOrg[seller.organization] || 0}
                                       showZero={false}/></Tooltip>}
                            {this.props.cancelOrderWithOrg[seller.organization] > 0 && <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[seller.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.returnOrderWithOrg[seller.organization] > 0 && <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[seller.organization] || 0} showZero={false}/></Tooltip>}
                    </span>,
                        name: seller.name,
                        organizationUnread: this.state.organizationUnread[seller.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[seller.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[seller.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[seller.organization] || 0
                    });
                });
            }

        }

        if (this.state.organization && this.state.organization.maker) {
            if ((this.props.org && this.props.org.display_name && !this.props.location.pathname.startsWith('/measurement'))) {
                addonBeforeName = '';
            } else {
                addonBeforeName = this.props.location.pathname.startsWith('/measurement') ? '[測定データモード]' : ''//'取引先を選択してください'
            }
            if (buyersOrganizations) {
                buyersOrganizations.map((buyers, i) => {
                    orgList.push({
                        id: buyers.organization,
                        value: `[売先] ${buyers.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                            {this.state.organizationUnread[buyers.organization] > 0 && <Tooltip title={'未読'}><Badge color={'#52c41a'} title={''} overflowCount={999} count={this.state.organizationUnread[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            [売先] {buyers.name}
                            {this.props.notShippedOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'未出荷'}><Badge color={'#f5222d'} title={''} overflowCount={999} count={this.props.notShippedOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} title={''} overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} title={''} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} title={''} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.cancelOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.returnOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                    </span>,
                        name: buyers.name,
                        organizationUnread: this.state.organizationUnread[buyers.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[buyers.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[buyers.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[buyers.organization] || 0
                    });
                });
            }
        }
        if(this.props.user.admin) {
            if(!this.props.location.pathname.startsWith('/measurement') && this.props.orgSeller && this.props.orgSeller.display_name) {
                addonBeforeName = ''//this.props.orgSeller.display_name;
            }
            else {
                addonBeforeName = this.props.location.pathname.startsWith('/measurement') ? '[測定データモード]' : '' //販売モード側事業所選択
            }
            if (sellerOrganizations) {
                sellerOrganizations.map((seller, i) => {
                    orgList.push({
                        id: seller.id,
                        value: `[販売側] ${seller.company_name} ${seller.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                            [販売側] {seller.company_name} {seller.name}
                            {this.props.sdsNormalCountWithOrg[seller.id] > 0 && <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} title={''} overflowCount={999} count={this.props.sdsNormalCountWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWarningCountWithOrg[seller.id] > 0 && <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} title={''} overflowCount={999} count={this.props.sdsWarningCountWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsAlertCountWithOrg[seller.id] > 0 && <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} title={''} overflowCount={999} count={this.props.sdsAlertCountWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWaitingCountWithOrg[seller.id] > 0 && <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            {this.props.cancelOrderWithOrg[seller.id] > 0 && <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            {this.props.returnOrderWithOrg[seller.id] > 0 && <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[seller.id] || 0} showZero={false}/></Tooltip>}
                            </span>,
                        name: seller.name,
                        name2: `${seller.company_name} ${seller.name}`,
                        organizationUnread: this.state.organizationUnread[seller.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[seller.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[seller.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[seller.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[seller.organization] || 0,
                        buyers: seller.buyers
                    });
                });
            }
            if (buyerOrganizations) {
                buyerOrganizations.map((buyers, i) => {
                    orgList2.push({
                        id: buyers.organization,
                        value: `[売先] ${buyers.name}`,
                        label: <span style={{display: 'flex', gap: 5}}>
                            [売先] {buyers.name}
                            {this.props.sdsNormalCountWithOrg[buyers.organization] > 0 && <Tooltip title={'正常更新中'}><Badge color={'#4185F4'} title={''}  overflowCount={999} count={this.props.sdsNormalCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWarningCountWithOrg[buyers.organization] > 0 && <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge color={'#FEBE48'} title={''} overflowCount={999} count={this.props.sdsWarningCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsAlertCountWithOrg[buyers.organization] > 0 && <Tooltip title={'チェック更新日期限切れ'}><Badge color={'#8000FF'} title={''} overflowCount={999} count={this.props.sdsAlertCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.sdsWaitingCountWithOrg[buyers.organization] > 0 && <Tooltip title={'SDS管理依頼中'}><Badge color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.cancelOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'キャンセル承認待'}><Badge style={{borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                            {this.props.returnOrderWithOrg[buyers.organization] > 0 && <Tooltip title={'返品承認待'}><Badge style={{borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrderWithOrg[buyers.organization] || 0} showZero={false}/></Tooltip>}
                        </span>,
                        name: buyers.name,
                        organizationUnread: this.state.organizationUnread[buyers.organization] || 0,
                        notShippedOrder: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsNormal: this.props.sdsNormalCountWithOrg[buyers.organization] || 0,
                        sdsWarning: this.props.sdsWarningCountWithOrg[buyers.organization] || 0,
                        sdsAlert: this.props.sdsAlertCountWithOrg[buyers.organization] || 0,
                        sdsWait: this.props.sdsWaitingCountWithOrg[buyers.organization] || 0
                    });
                });
            }
        }

        let kaigawa = false;
        let urigawa = false;
        let cancelTooltip = "キャンセル待ち";
        let returnTooltip = "返品待ち";
        if ( (this.props.userOrg && this.props.userOrg.buyer && this.props.org && this.props.org.reseller)
            || (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.maker)
        ){
            kaigawa = true;
            cancelTooltip = "キャンセル依頼中";
            returnTooltip = "返品依頼中";
        }
        if ( (this.props.userOrg && this.props.userOrg.reseller && this.props.org && this.props.org.buyer)
            || (this.props.userOrg && this.props.userOrg.maker && this.props.org && this.props.org.reseller)
        ){
            urigawa = true;
            cancelTooltip = "キャンセル承認待";
            returnTooltip = "返品承認待"
        }

        return (<>
            <Header
                style={{
                    padding: 0,
                    background: '#FFFFFF',
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    zIndex: 10
                }}
            >
                <div style={{flex:1, display: 'flex', alignItems: 'center',flexDirection:'row',justifyContent:'space-between', gap: 50}}>
                   <div style={{display: 'flex', justifyContent:'flex-start',flexDirection: 'row', alignItems: 'center', flex: 1}}>
                       <Button
                           type="text"
                           icon={this.props.collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                           onClick={() => this.props.setCollapsed(!this.props.collapsed)}
                           style={{
                               fontSize: '16px',
                               width: 64,
                               height: 64
                           }}
                       />
                       {/*<div className="d-none d-md-inline">
                        {(this.props.org && this.props.org.display_name && !this.props.location.pathname.startsWith('/measurement')) ?
                            <span style={{fontSize:'12pt'}}>
                                {this.props.org.maker ? '[仕入モード]　' : ''}
                                {this.props.org.buyer ? '[販売モード]　' : ''}
                            </span>
                            :
                            this.props.location.pathname.startsWith('/measurement') ? <span style={{fontSize:'12pt',marginRight:10}}>[測定データモード]</span> : <></>
                        }
                        </div>*/}
                      <div style={{fontSize:'14px',marginRight:10}}>{addonBeforeName}</div>
                       {
                           !this.props.user.admin ? <Select
                               showSearch={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                               style={{ flex: 1 }}
                               // popupMatchSelectWidth={600}
                               listHeight={'80vh'}
                               placeholder="取引先を選択してください"
                               optionFilterProp="value"
                               size={"large"}
                               options={orgList}
                               notFoundContent={'データなし'}
                               //defaultValue={this.props.org && this.props.org.display_name}
                               value={this.props.org && this.props.org.display_name}
                               onSearch={(text) => this.setState({searchOrg: text})}
                               onSelect={(value, option) => this.selectOrg(value, option)}
                               showArrow={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                               open={(this.props.userOrg && this.props.userOrg.buyer) ? false : undefined}
                               optionRender={(option) =>(
                                   <div className={`${this.props.org && this.props.org.id === option.data.id ? 'ant-select-item-option-selected' : ''}`}>{option.label}</div>)}>
                           </Select> :
                               <Select
                                   showSearch={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                                   style={{ flex:1 }}
                                   //popupMatchSelectWidth={400}
                                   listHeight={'80vh'}
                                   placeholder={"販売モード側事業所選択"}
                                   optionFilterProp="value"
                                   size={"large"}
                                   options={orgList}
                                   notFoundContent={'データなし'}
                                   //defaultValue={this.props.org && this.props.org.display_name}
                                   value={this.props.orgSeller && this.props.orgSeller.display_name}
                                   onSearch={(text) => this.setState({searchOrg: text})}
                                   onSelect={(value, option) => this.selectSellerOrganization(option, false)}
                                   showArrow={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                                   open={(this.props.userOrg && this.props.userOrg.buyer) ? false : undefined}
                                   optionRender={(option) =>{
                                       //console.log('option', option,this.props.orgSeller && this.props.orgSeller.display_name);
                                       return (
                                           <div
                                               className={`${this.props.orgSeller && this.props.orgSeller.id === option.data.id ? 'ant-select-item-option-selected' : ''}`}>{option.label}</div>)
                                   }}
                               >
                               </Select>
                       }
                       {
                           this.props.user.admin && !this.props.location.pathname.startsWith('/measurement') && <Select
                               showSearch={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                               style={{ flex:1,marginLeft:10 }}
                               //popupMatchSelectWidth={400}
                               listHeight={'80vh'}
                               placeholder="仕入モード側事業所選択"
                               optionFilterProp="value"
                               size={"large"}
                               options={orgList2}
                               notFoundContent={'データなし'}
                               //defaultValue={this.props.org && this.props.org.display_name}
                               value={this.props.org && this.props.org.display_name}
                               onSearch={(text) => this.setState({searchOrg: text})}
                               onSelect={(value, option) => this.selectOrg(value, option)}
                               showArrow={(this.props.userOrg && this.props.userOrg.buyer) ? false : true}
                               open={(this.props.userOrg && this.props.userOrg.buyer) ? false : undefined}
                               optionRender={(option) =>(
                                   <div className={`${this.props.org && this.props.org.id === option.data.id ? 'ant-select-item-option-selected' : ''}`}>{option.label}</div>)}
                           >
                           </Select>
                       }
                   </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: 15,
                        gap: 5
                    }}>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent:'flex-start'}}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5}}>
                                {this.state.unread > 0 && <Tooltip title={'通知'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px" fill="#5f6368">
                                        <path
                                            d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280L160-640v400h640v-400L480-440Zm0-80 320-200H160l320 200ZM160-640v-80 480-400Z"/>
                                    </svg>
                                </Tooltip>}
                                {this.state.unread > 0 && <Tooltip title={'未読'}>
                                        <Badge style={{cursor: 'pointer'}} color={'#52c41a'} overflowCount={999} title={''}
                                               count={this.state.unread}
                                               showZero={false}/>
                                </Tooltip>}

                                <Tooltip title={'SDS管理'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px" fill="#5f6368">
                                        <path
                                            d="m40-120 440-760 440 760H40Zm138-80h604L480-720 178-200Zm302-40q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Zm40-100Z"/>
                                    </svg>
                                </Tooltip>
                                {
                                    this.props.user.admin ? <>
                                        {
                                            this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                            <Tooltip title={'正常更新中'}>
                                                <Badge style={{cursor:'pointer'}} title={''} color={'#4185F4'} overflowCount={999} count={this.props.sdsNormalCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                            <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge style={{cursor:'pointer'}} title={''} color={'#FEBE48'} overflowCount={999} count={this.props.sdsWarningCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                            <Tooltip title={'チェック更新日期限切れ'}><Badge style={{cursor:'pointer'}} title={''} color={'#8000FF'} overflowCount={999} count={this.props.sdsAlertCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                            <Tooltip title={'SDS管理依頼中'}><Badge style={{cursor:'pointer'}} color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCountWithOrg[this.props.orgSeller && this.props.orgSeller.id]} showZero={false}/></Tooltip>
                                        }
                                    </> : <>
                                        {
                                            this.props.sdsNormalCount > 0 &&
                                            <Tooltip title={'正常更新中'}><Badge style={{cursor:'pointer'}} color={'#4185F4'} title={''} overflowCount={999} count={this.props.sdsNormalCount} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsWarningCount > 0 &&
                                            <Tooltip title={'チェック更新期限日まで1週間以内'}><Badge style={{cursor:'pointer'}} color={'#FEBE48'} title={''} overflowCount={999} count={this.props.sdsWarningCount} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsAlertCount > 0 &&
                                            <Tooltip title={'チェック更新日期限切れ'}><Badge style={{cursor:'pointer'}} color={'#8000FF'} title={''} overflowCount={999} count={this.props.sdsAlertCount} showZero={false}/></Tooltip>
                                        }
                                        {
                                            this.props.sdsWaitingCount > 0 &&
                                            <Tooltip title={'SDS管理依頼中'}><Badge style={{cursor:'pointer'}} color={'#58C7C4'} title={''} overflowCount={999} count={this.props.sdsWaitingCount} showZero={false}/></Tooltip>
                                        }
                                    </>
                                }
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 5,
                                marginRight: 40
                            }}>
                                {this.state.unreadNotShipped > 0 && <Tooltip title={'出荷状況'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960"
                                         width="24px" fill="#5f6368">
                                        <path
                                            d="M240-160q-50 0-85-35t-35-85H40v-440q0-33 23.5-56.5T120-800h560v160h120l120 160v200h-80q0 50-35 85t-85 35q-50 0-85-35t-35-85H360q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T280-280q0-17-11.5-28.5T240-320q-17 0-28.5 11.5T200-280q0 17 11.5 28.5T240-240ZM120-360h32q17-18 39-29t49-11q27 0 49 11t39 29h272v-360H120v360Zm600 120q17 0 28.5-11.5T760-280q0-17-11.5-28.5T720-320q-17 0-28.5 11.5T680-280q0 17 11.5 28.5T720-240Zm-40-200h170l-90-120h-80v120ZM360-540Z"/>
                                    </svg>
                                </Tooltip>}
                                {this.state.unreadNotShipped > 0 && <Tooltip title={'未出荷'}>
                                    <Badge style={{cursor: 'pointer'}} color={'#f5222d'} overflowCount={999} title={''}
                                           count={this.state.unreadNotShipped}
                                           showZero={false}/>
                                </Tooltip>}

                                <Tooltip title={'キャンセル/返品状況'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m691-150 139-138-42-42-97 95-39-39-42 43 81 81ZM240-600h480v-80H240v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40ZM120-80v-680q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v267q-19-9-39-15t-41-9v-243H200v562h243q5 31 15.5 59T486-86l-6 6-60-60-60 60-60-60-60 60-60-60-60 60Zm120-200h203q3-21 9-41t15-39H240v80Zm0-160h284q38-37 88.5-58.5T720-520H240v80Zm-40 242v-562 562Z"/></svg>
                                </Tooltip>
                                <>
                                    {/*{this.props.orgSeller && this.props.orgSeller.id}*/}
                                    {
                                        // this.props.cancelOrderWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                        this.props.cancelOrder > 0 &&
                                        <Tooltip title={cancelTooltip}><Badge style={{cursor:'pointer', borderRadius: 0}} color={'#ff63e0'} title={''} overflowCount={999} count={this.props.cancelOrder} showZero={false}/></Tooltip>
                                    }
                                    {
                                        // this.props.returnOrderWithOrg[this.props.orgSeller && this.props.orgSeller.id] > 0 &&
                                        this.props.returnOrder > 0 &&
                                        <Tooltip title={returnTooltip}><Badge style={{cursor:'pointer', borderRadius: 0}} color={'#00C2FF'} title={''} overflowCount={999} count={this.props.returnOrder} showZero={false}/></Tooltip>
                                    }
                                </>
                            </div>
                        </div>

                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        label: (<div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            padding: 20,
                                            gap: 5,
                                            alignItems: 'center'
                                        }}>
                                            <span>ユーザー情報</span>
                                            <span>{this.props.user.last_name} {this.props.user.first_name}</span>
                                            <span>{this.state.company ? this.state.company.name : ''}</span>
                                            <span>{this.state.organization ? this.state.organization.name : ''}</span>
                                        </div>),
                                        key: '0',
                                    },
                                    {
                                        label: (<span style={{display: 'flex', gap: 5}}><InfoCircleOutlined/> 操作方法</span>),
                                        key: '11'
                                    },
                                    {
                                        label: (<span style={{display: 'flex', gap: 5}}><MailOutlined/> 通知メール受信設定</span>),
                                        key: '1'
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        label: 'Logout',
                                        key: '2',
                                    },
                                ],
                                onClick: this.handleMenuClick
                            }}
                            trigger={['click']}
                            style={{margin: 20}}
                        >
                            <a onClick={(e) => e.preventDefault()}>

                                <Space>{this.props.user.last_name}{this.props.user.first_name}<DownOutlined/></Space>

                            </a>
                        </Dropdown>
                    </div>
                </div>

                {/*<div style={{padding: '0 15px'}}>

                </div>*/}
            </Header>
        </>)
    }
}

export default withRouter(P4Header1);
